'use client';
import React from 'react';

import { Layout } from 'antd';

import withTheme from '@shared/styles';

const DefaultLayout = ({ children }: { children: React.ReactNode }) =>
  withTheme(<Layout style={{ minHeight: '100vh' }}>{children}</Layout>);
export default DefaultLayout;
